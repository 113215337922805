<script>
  let name = "monthly-magnetograms";
  let year = parseInt(new Date().getFullYear());
  let month = parseInt(new Date().getMonth()) + 1;
  let month2String;
  let url;
  let urlF;
  let urlX;
  let urlY;
  let urlZ;
  let selectedDate,
    selectedTimestamp,
    nextTimpestamp,
    nextDate,
    prevTimestamp,
    prevDate;
    
  let availableYears = [];
  let availableMonths = [];
  let base_url =
      "https://observatory.geophysik.uni-muenchen.de/geomagnetism/data/datenMonth/"

  for (let y = 2001; y <= parseInt(new Date().getFullYear()); y++) {
    availableYears.push(y);
  }

  for (let m = 1; m <= 12; m++) {
    availableMonths.push(m);
  }

  $: {
    console.log("update url");

    selectedDate = new Date(year, month - 1, 1, 0, 0, 0);
    selectedTimestamp = Math.floor(selectedDate.getTime() / 1000);

    year = parseInt(new Date(selectedTimestamp * 1000).getFullYear());
    month = parseInt(new Date(selectedTimestamp * 1000).getMonth() + 1);

    //formatstring for javascript date

    if (month < 10) {
      month2String = "0" + String(month);
    } else {
      month2String = String(month);
    }

    url =
      base_url +
      year +
      "/" +
      year +
      month2String;

    urlF =
      url +
      "_F.JPG";

    urlX =
      url +
      "_X.JPG";

    urlY =
      url +
      "_Y.JPG";

    urlZ =
      url +
      "_Z.JPG";
  }

  function goNext() {
    nextTimpestamp = selectedTimestamp + (32 * 24 * 60 * 60);
    nextDate = new Date(nextTimpestamp * 1000);
    year = parseInt(nextDate.getFullYear());
    month = parseInt(nextDate.getMonth()) + 1;
  }

  function goPrev() {
    prevTimestamp = selectedTimestamp - (28 * 24 * 60 * 60);
    prevDate = new Date(prevTimestamp * 1000);
    year = parseInt(prevDate.getFullYear());
    month = parseInt(prevDate.getMonth()) + 1;
  }

  function goNow() {
    let timestamp = Math.floor(Date.now() / 1000);
    let now = new Date(timestamp * 1000);
    year = parseInt(now.getFullYear());
    month = parseInt(now.getMonth()) + 1;
  }

</script>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  form {
    max-width: 100%;
    max-height:auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
    max-height: auto;
  }


  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<!--<svelte:options tag="monthly-magnetograms" /> -->

<main>
  <form action="" method="GET">
    <!-- <label for="year">year:</label> -->
    <select bind:value={year} name="year" id="year" class="form-select col-md-2 ms-md-auto">
      {#each availableYears as ayear}
        <option value={ayear}>{ayear}</option>
      {/each}
    </select>
    <!-- <label for="month">month:</label> -->
    <select bind:value={month} name="month" id="month" class="form-select col-md-1 ms-md-auto">
      {#each availableMonths as amonth}
        <option value={amonth}>{amonth}</option>
      {/each}
    </select>
  </form>
  <form action="" method="get" id="navigation">
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goPrev}
      value="Prev"
    />
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goNow}
      value="Now"
    />
    <input
      type="submit"
      name="button"
      on:click|preventDefault={goNext}
      value="Next"
    />
  </form>
  <div>
    <img
      src={urlF}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
  <div>
    <img
      src={urlX}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
  <div>
    <img
      src={urlY}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
  <div>
    <img
      src={urlZ}
      alt=""
      onerror="javascript:this.src=''"
    />
  </div>
</main>
